<template>
 <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="column my-1">
      <p class="text-xl">Enviar consulta</p>
      <div class="columns">
          <div class="column is-4">
            <ValidInput
                :rules="'required'"
                label="Nombre de institución"
                placeholder="Nombre de institución"
                v-model="nombre_institucion"
                type="text"
                :expanded="true"
              />
           </div>
          <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Nombre del sector"
                placeholder="Seleccionar..."
                v-model="nombre_sector"
                :data="sectores"
                show="nombre"
                realValue="nombre"
                :expanded="true"
              />
           </div>
        </div>
        <div class="columns">
           <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Trimestre del reporte"
                placeholder="Seleccionar..."
                v-model="trimestre"
                :data="trimestres"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
          <div class="column is-4">
            <ValidInput
                :rules="'required'"
                label="Número de folio"
                placeholder="Número de folio"
                v-model="folio"
                type="text"
                :expanded="true"
              />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Estatus"
                placeholder="Seleccionar..."
                v-model="status"
                :data="status_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
        <div class="column is-4">
          <label for=""><b>Fecha atención</b></label>
          <valid-datepicker
            :rules="status == 1 ? 'required' : ''"
            placeholder="seleccionar día..."
            v-model="fecha_atencion"
            position="is-bottom-left"
            :append-to-body="true"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <label for=""><b>Fecha recepción</b></label>
          <valid-datepicker
            rules="required"
            placeholder="seleccionar día..."
            v-model="fecha_recepcion"
            position="is-bottom-left"
            :append-to-body="true"
          />
        </div>
      </div>
      <div class="columns">

        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Medio de recepción"
                placeholder="Seleccionar..."
                v-model="medio"
                :data="getCondusefMediosRecepcion.medio"
                show="medioDsc"
                realValue="medioId"
                :expanded="true"
              />
        </div>
        <div class="column is-4">
              <valid-select
                :rules="status == 1 ? 'required' : ''"
                label="Nivel de atención"
                placeholder="Seleccionar..."
                v-model="nivel"
                :data="getCondusefNivelesAtencion.nivelesDeAtencion"
                show="nivelDeAtencionDsc"
                realValue="nivelDeAtencionId"
                :expanded="true"
              />
          </div>
      </div>
     
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Producto"
                placeholder="Seleccionar..."
                v-model="producto"
                :data="getCondusefProductos.products"
                show="product"
                realValue="productId"
                :expanded="true"
                @input="loadCausas"
              />
        </div>
          <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Causas"
                placeholder="Seleccionar..."
                v-model="causa"
                :data="getCondusefCausas.causas"
                show="causa"
                realValue="causaId"
                :expanded="true"
                :disabled="!producto"
              />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="PORI (Posible Robo de Identidad)"
                placeholder="Seleccionar..."
                v-model="pori"
                :data="pori_list"
                show="value"
                realValue="value"
                :expanded="true"
              />
          </div>

      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Estado"
                placeholder="Seleccionar..."
                v-model="estado"
                :data="getCondusefEstados.estados"
                show="estado"
                realValue="claveEdo"
                :expanded="true"
                @input="loadCP"
              />
          </div>
          <div class="column is-4">
            <!-- change to autocomplete -->
              <valid-select
                :rules="'required'"
                label="Codigo Postal"
                placeholder="Seleccionar..."
                v-model="codigo_postal"
                :data="getCondusefCodigosPostales.codigos_postales"
                show="codigo_sepomex"
                realValue="codigo_sepomex"
                :expanded="true"
                :disabled="!estado"
                @input="loadMunicipios"
              />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Municipio"
                placeholder="Seleccionar..."
                v-model="municipio"
                :data="getCondusefMunicipios.municipios"
                show="municipio"
                realValue="municipioId"
                :expanded="true"
                :disabled="!codigo_postal"
              />
        </div>
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Colonia"
                placeholder="Seleccionar..."
                v-model="colonia"
                :data="getCondusefColonias.colonias"
                show="colonia"
                realValue="coloniaId"
                :expanded="true"
                :disabled="!codigo_postal"
              />
        </div>
      </div>
     
      <div class="colmuns has-text-danger" v-if="errors">
        <p class="text-xl">Errores detectados</p>
        {{ errors }}
      </div>
      <div class="columns mt-4">
            <div class="column is-8">
              <b-button
                type="is-primary"
                expanded
                class="font-bold has-background-grey-darker has-text-white"
                @click="validate().then(result => guardar(result))"
              >
                Enviar Consulta
              </b-button>
            </div>
          </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";


export default {
  name: "ReuneConsultasForm",
  components: {
    ValidationObserver,
    ValidSelect,
    ValidInput,
    ValidDatepicker
  },
  props: { },
  data() {
    return {
      nombre_institucion:null,
      nombre_sector:null,
      trimestre:null,
      folio:null,
      medio:null,
      nivel:null,
      fecha_atencion:null,
      fecha_recepcion:null,
      producto:null,
      causa:null,
      codigo_postal:null,
      pori:null,
      status:null,
      estado:null,
      municipio:null,
      colonia:null,
      errors:null,
      trimestres:[
        {id:1, nombre: '1er Trimestre'},
        {id:2, nombre: '2do Trimestre'},
        {id:3, nombre: '3er Trimestre'},
        {id:4, nombre: '4to Trimestre'},
      ],
      pori_list:[
        {value: 'SI'},
        {value: 'NO'}
      ],
      status_list:[
        {id:1, nombre:'Concluido'},
        {id:2, nombre:'Pendiente'}
      ],
      tipo_persona_list:[
        {id:1, nombre:'Persona Física'},
        {id:2, nombre:'Persona Moral'}
      ],
      resolucion_list:[
        {id:1, nombre:'Totalmente favorable al usuario'},
        {id:2, nombre:'Desfavorable al Usuario'},
        {id:3, nombre:'Parcialmente favorable al usuario'}
      ],
      tipo_penal_list:[
        {id:1, nombre:'Contractuales - Cancelación del contrato'},
        {id:2, nombre:'Contractuales - Reasignación de cartera'},
        {id:3, nombre:'Económicas - Multa'}
      ],
      sexo_list:[
        {id:'H', nombre:'HOMBRE'},
        {id:'M', nombre:'MUJER'}
      ],
      sectores:[
        {nombre:'Afores'},
        {nombre:'Almacenes Generales de Depósito'},
        {nombre:'Casas de Bolsa'},
        {nombre:'Casas de Cambio'},
        {nombre:'Fianzas'},
        {nombre:'Financiera Rural'},
        {nombre:'Instituciones de Financiamiento Colectivo'},
        {nombre:'Instituciones de Fondos de Pago Electrónico'},
        {nombre:'Operadores del Mercado de Derivados'},
        {nombre:'Organismos de Servicio Social '},
        {nombre:'Pensiones'},
        {nombre:'Salud'},
        {nombre:'Sociedades Cooperativas de Ahorro y Préstamo'},
        {nombre:'Sociedades de Inversión'},
        {nombre:'Sociedades Distribuidoras de Acciones de Sociedades de Inversión'},
        {nombre:'Sociedades Financieras Comunitarias'},
        {nombre:'Sociedades Financieras Populares'},
        {nombre:'Sociedades Operadoras de Fondos de Inversión'},
        {nombre:'SOFOM, E.N.R.'},
        {nombre:'SOFOM, E.R.'},
        {nombre:'Uniones de Crédito'}
      ]
    };
  },
  methods:{
    loadCausas(value) {
      let payload= {
        'product':value,
        'api_type':'reune'
      };
      this.$store.dispatch("reportes/getCondusefCausas", payload);
    },
    loadCP(value) {
      let payload= {
        'estado_id':value,
        'api_type':'reune'
      };
      this.$store.dispatch("reportes/getCondusefCodigosPostales", payload);
    },
    loadMunicipios(value) {
      let payload= {
        'estado_id':this.estado,
        'cp':value,
        'api_type':'reune'
      };
      this.$store.dispatch("reportes/getCondusefMunicipios", payload);
      let payload2= {
        'cp':value,
        'api_type':'reune'
      };
      this.$store.dispatch("reportes/getCondusefColonias", payload2);
    },
    dateFormatter(date) {
      if(date != null)
        return (
          this.$moment(date).format("DD/MM/YYYY")
        );
      else
        return null;
    },
    formatPayload(){
      return {
        "InstitucionClave": this.nombre_institucion,
        "Sector": this.nombre_sector,
        "ConsultasTrim": this.trimestre,
        "NumConsultas": 1,
        "ConsultasFolio": this.folio,
        "ConsultasEstatusCon": this.status,
        "ConsultasFecAten":  this.dateFormatter(this.fecha_atencion),
        "ConsultasFecRecepcion":  this.dateFormatter(this.fecha_recepcion),
        "MediosId": this.medio,
        "Producto": this.producto,
        "CausaId": this.causa,
        "EstadosId": this.estado,
        "ConsultasCP": this.codigo_postal,
        "ConsultasMpioId": this.municipio,
        "ConsultasLocId": null,
        "ConsultasColId": this.colonia,
        "ConsultascatnivelatenId": this.nivel,
        "ConsultasPori": this.pori
      }
              
    },
    guardar(result) {
      if (!result) {
        return;
      }
      let payload = this.formatPayload();
      this.$store
        .dispatch("reportes/postCondusefReuneConsulta", payload)
        .then(data => {
          if(data.errors){
            this.errors = data.errors[0]['queja']['errors'].join(', ');
          }
          if(data.error){
            this.errors = data.error;
          }
          if(!data.error && !data.errors){
            this.notification(
              "success",
              "Se envió con éxito",
              "Se generó la queja correspondiente"
            );
          }
        });
    }
  },  
  getCatalogos(){
    if(this.getCondusefEstados.length == 0)
      this.$store.dispatch("reportes/getCondusefEstados", { api_type:'reune' });
    if(this.getCondusefMediosRecepcion.length == 0)
      this.$store.dispatch("reportes/getCondusefMediosRecepcion", { api_type:'reune' });
    if(this.getCondusefNivelesAtencion.length == 0)
      this.$store.dispatch("reportes/getCondusefNivelesAtencion", { api_type:'reune' });
    if(this.getCondusefProductos.length == 0)
      this.$store.dispatch("reportes/getCondusefProductos", { api_type:'reune' });
  },
  async created() {
    if(this.getCondusefConfigServiceReune == null){
      await this.$store.dispatch("reportes/getCondusefConfigServiceReune");
    }
    if(this.getCondusefConfigServiceReune !== null){
      if(this.getCondusefConfigServiceReune.status == 'success'){
        this.getCatalogos();
      }
      if(this.getCondusefConfigServiceReune.status == 'error'){
        this.notification(
              "warning",
              "Condusef Reune",
              this.getCondusefConfigServiceReune.message
            );
      }
    }
  },
  computed: {
    ...mapGetters("reportes", [
      "getCondusefEstados",
      "getCondusefMediosRecepcion",
      "getCondusefNivelesAtencion",
      "getCondusefProductos",
      "getCondusefCausas",
      "getCondusefCodigosPostales",
      "getCondusefMunicipios",
      "getCondusefColonias",
      "getCondusefConfigServiceReune"
    ])
  }
  
};
</script>
