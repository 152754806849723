var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 p-0 text-left"},[_c('p',[_vm._v(" En este reporte podrás consultar las quejas por mes y año. ")]),_c('ReuneForm'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4 text-left"},[_c('b-field',{staticClass:"mt-4"},[_c('b-datepicker',{attrs:{"placeholder":"Selecciona una fecha del reporte...","type":"month","trap-focus":"","icon":"calendar","dateFormatter":_vm.dateFormatter,"month-names":[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
              ]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('div',{staticClass:"column is-4 text-left"},[_c('b-button',{staticClass:"is-primary mt-4",attrs:{"type":"is-light","disabled":!_vm.date},on:{"click":_vm.consultar}},[_vm._v(" Consultar ")])],1)])],1)]),_c('div',{staticClass:"columns"},[(_vm.getCondusefQuejas.quejas && _vm.getCondusefQuejas.quejas.length == 0)?_c('div',{staticClass:"column"},[_c('span',[_vm._v("No existen quejas en este periodo consultado")])]):_vm._e(),(_vm.getCondusefQuejas.quejas && _vm.getCondusefQuejas.quejas.length > 0)?_c('div',{staticClass:"column is-12 text-left"},[_c('b-table',{attrs:{"data":_vm.getCondusefQuejas.quejas}},[_c('b-table-column',{attrs:{"field":"institucionClave","subheading":"Clave institucion","custom-key":"institucionClave","header-class":"px-16","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.institucionClave)+" ")]}}],null,false,352024131)}),_c('b-table-column',{attrs:{"field":"folio","subheading":"Folio","custom-key":"folio","header-class":"px-16","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.folio)+" ")]}}],null,false,3549423006)}),_c('b-table-column',{attrs:{"field":"month","subheading":"Mes","custom-key":"month","header-class":"px-16","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.month)+" ")]}}],null,false,3504482861)})],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }