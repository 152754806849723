<template>
 <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="column my-1">
      <p class="text-xl">Enviar queja</p>
      <div class="columns">
          <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Mes del reporte"
                placeholder="Seleccionar..."
                v-model="mes"
                :data="meses"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
          <div class="column is-4">
            <ValidInput
                :rules="'required'"
                label="Número de folio"
                placeholder="Número de folio"
                v-model="folio"
                type="text"
                :expanded="true"
              />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Medio de recepción"
                placeholder="Seleccionar..."
                v-model="medio"
                :data="getCondusefMediosRecepcion.medio"
                show="medioDsc"
                realValue="medioId"
                :expanded="true"
              />
        </div>
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Nivel de atención"
                placeholder="Seleccionar..."
                v-model="nivel"
                :data="getCondusefNivelesAtencion.nivelesDeAtencion"
                show="nivelDeAtencionDsc"
                realValue="nivelDeAtencionId"
                :expanded="true"
              />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <label for=""><b>Fecha recepción</b></label>
          <valid-datepicker
            rules="required"
            placeholder="seleccionar día..."
            v-model="fecha_recepcion"
            position="is-bottom-left"
            :append-to-body="true"
          />
        </div>
        
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Producto"
                placeholder="Seleccionar..."
                v-model="producto"
                :data="getCondusefProductos.products"
                show="product"
                realValue="productId"
                :expanded="true"
                @input="loadCausas"
              />
        </div>
          <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Causas"
                placeholder="Seleccionar..."
                v-model="causa"
                :data="getCondusefCausas.causas"
                show="causa"
                realValue="causaId"
                :expanded="true"
                :disabled="!producto"
              />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="PORI (Posible Robo de Identidad)"
                placeholder="Seleccionar..."
                v-model="pori"
                :data="pori_list"
                show="value"
                realValue="value"
                :expanded="true"
              />
          </div>
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Estatus"
                placeholder="Seleccionar..."
                v-model="status"
                :data="status_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Estado"
                placeholder="Seleccionar..."
                v-model="estado"
                :data="getCondusefEstados.estados"
                show="estado"
                realValue="claveEdo"
                :expanded="true"
                @input="loadCP"
              />
          </div>
          <div class="column is-4">
            <!-- change to autocomplete -->
              <valid-select
                :rules="'required'"
                label="Codigo Postal"
                placeholder="Seleccionar..."
                v-model="codigo_postal"
                :data="getCondusefCodigosPostales.codigos_postales"
                show="codigo_sepomex"
                realValue="codigo_sepomex"
                :expanded="true"
                :disabled="!estado"
                @input="loadMunicipios"
              />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Municipio"
                placeholder="Seleccionar..."
                v-model="municipio"
                :data="getCondusefMunicipios.municipios"
                show="municipio"
                realValue="municipioId"
                :expanded="true"
                :disabled="!codigo_postal"
              />
        </div>
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Colonia"
                placeholder="Seleccionar..."
                v-model="colonia"
                :data="getCondusefColonias.colonias"
                show="colonia"
                realValue="coloniaId"
                :expanded="true"
                :disabled="!codigo_postal"
              />
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <valid-select
                :rules="'required'"
                label="Tipo Persona"
                placeholder="Seleccionar..."
                v-model="tipo_persona"
                :data="tipo_persona_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
        <div class="column is-4">
              <valid-select
                :rules="tipo_persona == 1 ? 'required' : ''"
                label="Sexo"
                placeholder="Seleccionar..."
                v-model="sexo"
                :data="sexo_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>

      </div>
      <div class="columns">
          <div class="column is-4">
              <ValidInput
                :rules="tipo_persona == 1 ? 'required' : ''"
                label="Edad"
                placeholder="Edad"
                v-model="edad"
                type="number"
                :expanded="true"
              />
          </div>
          <div class="column is-4">
            <label for=""><b>Fecha resolución</b></label>
            <valid-datepicker
              :rules="status == 2 ? 'required' : ''"
              placeholder="seleccionar día..."
              v-model="fecha_resolucion"
              position="is-bottom-left"
              :append-to-body="true"
            />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <label for=""><b>Fecha notificación</b></label>
          <valid-datepicker
            :rules="status == 2 ? 'required' : ''"
            placeholder="seleccionar día..."
            v-model="fecha_notificacion"
            position="is-bottom-left"
            :append-to-body="true"
          />
        </div>
        <div class="column is-4">
              <valid-select
                :rules="status == 2 ? 'required' : ''"
                label="Resolución"
                placeholder="Seleccionar..."
                v-model="resolucion"
                :data="resolucion_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
      </div>
      <div class="columns">
        <div class="column is-4">
              <ValidInput
                :rules="resolucion == 1 || resolucion == 3 ? 'required' : ''"
                label="Número de penalización"
                placeholder="Número de penalizaciones"
                v-model="num_penalizacion"
                type="number"
                :expanded="true"
              />
          </div>
        <div class="column is-4">
              <valid-select
                :rules="resolucion == 1 || resolucion == 3 ? 'required' : ''"
                label="Tipo de penalización"
                placeholder="Seleccionar..."
                v-model="tipo_penal"
                :data="tipo_penal_list"
                show="nombre"
                realValue="id"
                :expanded="true"
              />
          </div>
      </div>
      <div class="colmuns has-text-danger" v-if="errors">
        <p class="text-xl">Errores detectados</p>
        {{ errors }}
      </div>
      <div class="columns mt-4">
            <div class="column is-8">
              <b-button
                type="is-primary"
                expanded
                class="font-bold has-background-grey-darker has-text-white"
                @click="validate().then(result => guardar(result))"
              >
                Enviar Queja
              </b-button>
            </div>
          </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";


export default {
  name: "RedecoForm",
  components: {
    ValidationObserver,
    ValidSelect,
    ValidInput,
    ValidDatepicker
  },
  props: { },
  data() {
    return {
      mes:null,
      folio:null,
      medio:null,
      nivel:null,
      fecha_recepcion:null,
      producto:null,
      causa:null,
      codigo_postal:null,
      pori:null,
      status:null,
      estado:null,
      municipio:null,
      colonia:null,
      tipo_persona:null,
      sexo:null,
      fecha_resolucion:null,
      fecha_notificacion:null,
      resolucion:null,
      edad:null,
      num_penalizacion:null,
      tipo_penal:null,
      errors:null,
      meses:[
        {id:1, nombre: 'Enero'},
        {id:2, nombre: 'Febrero'},
        {id:3, nombre: 'Marzo'},
        {id:4, nombre: 'Abril'},
        {id:5, nombre: 'Mayo'},
        {id:6, nombre: 'Junio'},
        {id:7, nombre: 'Julio'},
        {id:8, nombre: 'Agosto'},
        {id:9, nombre: 'Septiembre'},
        {id:10, nombre: 'Octubre'},
        {id:11, nombre: 'Noviembre'},
        {id:12, nombre: 'Diciembre'}
      ],
      pori_list:[
        {value: 'SI'},
        {value: 'NO'}
      ],
      status_list:[
        {id:1, nombre:'Pendiente'},
        {id:2, nombre:'Concluido'}
      ],
      tipo_persona_list:[
        {id:1, nombre:'Persona Física'},
        {id:2, nombre:'Persona Moral'}
      ],
      resolucion_list:[
        {id:1, nombre:'Totalmente favorable al usuario'},
        {id:2, nombre:'Desfavorable al Usuario'},
        {id:3, nombre:'Parcialmente favorable al usuario'}
      ],
      tipo_penal_list:[
        {id:1, nombre:'Contractuales - Cancelación del contrato'},
        {id:2, nombre:'Contractuales - Reasignación de cartera'},
        {id:3, nombre:'Económicas - Multa'}
      ],
      sexo_list:[
        {id:'H', nombre:'HOMBRE'},
        {id:'M', nombre:'MUJER'}
      ]
    };
  },
  methods:{
    loadCausas(value) {
      let payload= {
        'product':value,
        'api_type':'redeco'
      };
      this.$store.dispatch("reportes/getCondusefCausas", payload);
    },
    loadCP(value) {
      let payload= {
        'estado_id':value,
        'api_type':'redeco'
      };
      this.$store.dispatch("reportes/getCondusefCodigosPostales", payload);
    },
    loadMunicipios(value) {
      let payload= {
        'estado_id':this.estado,
        'cp':value,
        'api_type':'redeco'
      };
      this.$store.dispatch("reportes/getCondusefMunicipios", payload);
      let payload2= {
        'cp':value,
        'api_type':'redeco'
      };
      this.$store.dispatch("reportes/getCondusefColonias", payload2);
    },
    dateFormatter(date) {
      if(date != null)
        return (
          this.$moment(date).format("DD/MM/YYYY")
        );
      else
        return null;
    },
    formatPayload(){
      return {
        "QuejasNoTrim": this.mes,
        "QuejasNum": 1,
        "QuejasFolio": this.folio,
        "QuejasFecRecepcion": this.dateFormatter(this.fecha_recepcion),
        "MedioId": this.medio,
        "NivelATId": this.nivel,
        "product": this.producto,
        "CausasId": this.causa,
        "QuejasPORI": this.pori,
        "QuejasEstatus": this.status,
        "EstadosId": this.estado,
        "QuejasMunId": this.municipio,
        "QuejasLocId": null,
        "QuejasColId": this.colonia,
        "QuejasCP": this.codigo_postal,
        "QuejasTipoPersona": this.tipo_persona,
        "QuejasSexo": this.sexo,
        "QuejasEdad": this.edad,
        "QuejasFecResolucion": this.dateFormatter(this.fecha_resolucion),
        "QuejasFecNotificacion": this.dateFormatter(this.fecha_notificacion),
        "QuejasRespuesta": this.resolucion,
        "QuejasNumPenal": this.num_penalizacion,
        "PenalizacionId": this.tipo_penal
        }
              
    },
    guardar(result) {
      if (!result) {
        return;
      }
      let payload = this.formatPayload();
      this.$store
        .dispatch("reportes/postCondusefRedeco", payload)
        .then(data => {
          if(data.errors){
            this.errors = data.errors[0]['queja']['errors'].join(', ');
          }
          if(data.error){
            this.errors = data.error;
          }
          if(!data.error && !data.errors){
            this.notification(
              "success",
              "Se envió con éxito",
              "Se generó la queja correspondiente"
            );
          }
        });
    },
    getCatalogos(){
      if(this.getCondusefEstados.length == 0)
        this.$store.dispatch("reportes/getCondusefEstados", { api_type:'redeco' });
      if(this.getCondusefMediosRecepcion.length == 0)
        this.$store.dispatch("reportes/getCondusefMediosRecepcion", { api_type:'redeco' });
      if(this.getCondusefNivelesAtencion.length == 0)
        this.$store.dispatch("reportes/getCondusefNivelesAtencion", { api_type:'redeco' });
      if(this.getCondusefProductos.length == 0)
        this.$store.dispatch("reportes/getCondusefProductos", { api_type:'redeco' });
    }
  },  
  async created() {
    if(this.getCondusefConfigServiceRedeco == null){
      await this.$store.dispatch("reportes/getCondusefConfigServiceRedeco");
    }
    if(this.getCondusefConfigServiceRedeco !== null){
      if(this.getCondusefConfigServiceRedeco.status == 'success'){
        this.getCatalogos();
      }
      if(this.getCondusefConfigServiceRedeco.status == 'error'){
        this.notification(
              "warning",
              "Condusef Redeco",
              this.getCondusefConfigServiceRedeco.message
            );
      }
    }
  },
  computed: {
    ...mapGetters("reportes", [
      "getCondusefEstados",
      "getCondusefMediosRecepcion",
      "getCondusefNivelesAtencion",
      "getCondusefProductos",
      "getCondusefCausas",
      "getCondusefCodigosPostales",
      "getCondusefMunicipios",
      "getCondusefColonias",
      "getCondusefConfigServiceRedeco"
    ])
  }
};
</script>
