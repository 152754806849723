<template>
  <layout class="main-cols">
    <div class="container is-fluid md:ml-0">
      <div class="columns">
        <div class="column">
          <p class="title has-text-grey-darker text-left">
            Reporte condusef
          </p>
          <b-tabs type="is-boxed" v-model="activeTab">
            <b-tab-item label="Redeco Busqueda" class="my-5">
              <RedecoSearch></RedecoSearch>     
            </b-tab-item>
            <b-tab-item label="Redeco Quejas" class="my-5">
              <RedecoForm></RedecoForm>     
            </b-tab-item>
            <b-tab-item label="Reune Consultas" class="my-5">
              <ReuneConsultasForm></ReuneConsultasForm>     
            </b-tab-item>
            <b-tab-item label="Reune Reclamaciones" class="my-5">
              <ReuneReclamacionesForm></ReuneReclamacionesForm>     
            </b-tab-item>
        </b-tabs>
          
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import RedecoSearch from "@/views/reportes/RedecoSearch";
import RedecoForm from "@/views/reportes/RedecoForm";
import ReuneConsultasForm from "@/views/reportes/ReuneConsultasForm";
import ReuneReclamacionesForm from "@/views/reportes/ReuneReclamacionesForm";
export default {
  name: "Condusef",
  components: {
    ReuneConsultasForm,
    ReuneReclamacionesForm,
    RedecoSearch,
    RedecoForm
  },
  data() {
    return {
      activeTab: 0
    }
  },
  props: { },
  methods: { }
};
</script>
