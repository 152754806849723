<template>
  <div>
    <div class="columns is-multiline">
        <div class="column is-12 p-0 text-left">
          <p>
            En este reporte podrás consultar las quejas por mes y año.
          </p>
          <ReuneForm></ReuneForm>
          <!-- <RedecoForm></RedecoForm> -->
          <div class="columns">
            <div class="column is-4 text-left">
              <b-field class="mt-4">
                <b-datepicker v-model="date" placeholder="Selecciona una fecha del reporte..." type="month" trap-focus
                  icon="calendar" :dateFormatter="dateFormatter" :month-names="[
                  'Enero',
                  'Febrero',
                  'Marzo',
                  'Abril',
                  'Mayo',
                  'Junio',
                  'Julio',
                  'Agosto',
                  'Septiembre',
                  'Octubre',
                  'Noviembre',
                  'Diciembre'
                ]" />
              </b-field>
            </div>
            <div class="column is-4 text-left">
              <b-button type="is-light" class="is-primary mt-4" @click="consultar" :disabled="!date">
                Consultar
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column" v-if="getCondusefQuejas.quejas && getCondusefQuejas.quejas.length == 0">
          <span>No existen quejas en este periodo consultado</span>
        </div>
        <div class="column is-12 text-left" v-if="getCondusefQuejas.quejas && getCondusefQuejas.quejas.length > 0">
          
          <b-table :data="getCondusefQuejas.quejas"  >
            <b-table-column
              field="institucionClave"
              subheading="Clave institucion"
              custom-key="institucionClave"
              header-class="px-16"
              sortable
              v-slot="props"
            >
              {{ props.row.institucionClave }}
            </b-table-column>
            <b-table-column
              field="folio"
              subheading="Folio"
              custom-key="folio"
              header-class="px-16"
              sortable
              v-slot="props"
            >
              {{ props.row.folio }}
            </b-table-column>
            <b-table-column
              field="month"
              subheading="Mes"
              custom-key="month"
              header-class="px-16"
              sortable
              v-slot="props"
            >
              {{ props.row.month }}
            </b-table-column>
          </b-table>
        </div>
      </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "RedecoSearch",
  data() {
    return {
      date: null,
      min_date: null,
      max_date: null,
      months: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ]
    }
  },
  computed: {
    ...mapGetters("reportes", ["getCondusefQuejas", "getCondusefConfigServiceRedeco"]),
  },
  props: {
  },
  methods: {
    dateFormatter(date) {
      return (
        this.$moment(date).format("MM/YYYY")
      );
    },

    consultar() {
      let splitted_date = this.$moment(this.date).format("MM/YYYY").split('/')
      let params = {
        month: parseInt(splitted_date[0]),
        year: splitted_date[1]
      }
      if(this.getCondusefConfigServiceRedeco == null){
        this.$store.dispatch("reportes/getCondusefConfigServiceRedeco");
      }
      if(this.getCondusefConfigServiceRedeco !== null){
        if(this.getCondusefConfigServiceRedeco.status == 'success'){
          this.$store.dispatch("reportes/getCondusefQuejas", params);
        }
        if(this.getCondusefConfigServiceRedeco.status == 'error'){
          this.notification(
                "warning",
                "Condusef",
                this.getCondusefConfigServiceRedeco.message
              );
        }
      }
    }
  }
};
</script>
